import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.20_@babel+core@7.26.7_@opentelemetry+api@1.9.0_@playwright+test@1.50.1_react-dom@18_nj2u5fwuic6phqql7bw45ijx24/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.20_@babel+core@7.26.7_@opentelemetry+api@1.9.0_@playwright+test@1.50.1_react-dom@18_nj2u5fwuic6phqql7bw45ijx24/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.20_@babel+core@7.26.7_@opentelemetry+api@1.9.0_@playwright+test@1.50.1_react-dom@18_nj2u5fwuic6phqql7bw45ijx24/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.20_@babel+core@7.26.7_@opentelemetry+api@1.9.0_@playwright+test@1.50.1_react-dom@18_nj2u5fwuic6phqql7bw45ijx24/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.20_@babel+core@7.26.7_@opentelemetry+api@1.9.0_@playwright+test@1.50.1_react-dom@18_nj2u5fwuic6phqql7bw45ijx24/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.20_@babel+core@7.26.7_@opentelemetry+api@1.9.0_@playwright+test@1.50.1_react-dom@18_nj2u5fwuic6phqql7bw45ijx24/node_modules/next/dist/client/components/render-from-template-context.js");
